<i18n>
  {
    "es": {
      "CHANGE_LANGUAGE": "Cambiar país e idioma",
      "CHANGE_LANGUAGE_INFO": "Compra desde el país que prefieras. Recuerda que solo podremos enviar tu pedido a direcciones ubicadas en el país escogido",
      "GO_SHOP": "Ir a la tienda"
    },
    "en": {
      "CHANGE_LANGUAGE": "Change Country and Language",
      "CHANGE_LANGUAGE_INFO": "Shop from the country you prefer. Remember that we can only ship your order to addresses located in the chosen country.",
      "GO_SHOP": "Go to the store",
    }   
  }
</i18n>

<template>
  <teleport to="body">
    <div
      class="mdplus:fixed mdplus:top-0 mdplus:left-0 mdplus:h-full mdplus:w-full mdplus:bg-black mdplus:bg-opacity-40 mdplus:backdrop-blur-sm"
      @click="close"
    ></div>
    <div class="mdplus:flex mdplus:items-center mdplus:justify-center">
      <div
        class="fixed inset-y-0 right-0 z-50 flex h-screen w-screen items-center justify-center bg-neutral-black-1 bg-opacity-50 mdplus:fixed mdplus:top-0 mdplus:left-0 mdplus:m-auto mdplus:h-fit mdplus:w-countryselector mdplus:rounded-lg mdplus:bg-transparent"
      >
        <div
          class="0.5s bg-white p-2.5 transition-all ease-in mdplus:rounded-lg"
          :class="
            expanded || !challenge
              ? 'h-screen w-screen mdplus:h-auto'
              : 'mx-5 w-full rounded-lg shadow-xl'
          "
        >
          <div class="flex justify-end">
            <button @click="close">
              <img
                src="@/assets/svg/icons/icons-close.svg"
                alt="icono de cerrar"
              />
            </button>
          </div>
          <div class="px-3.5">
            <div class="flex justify-center">
              <img
                src="@/assets/svg/icons/icons-world.svg"
                width="30"
                height="30"
                alt=""
              />
            </div>
            <div
              class="py-2.5 text-center font-bodoni text-base tracking-wider"
            >
              {{ t('CHANGE_LANGUAGE') }}
            </div>
            <div
              class="py-2.5 text-center text-sm leading-5 text-neutral-black-3"
            >
              {{ t('CHANGE_LANGUAGE_INFO') }}
            </div>
            <div class="py-2.5">
              <div v-if="expanded || !challenge" class="divide-y">
                <div
                  v-for="country in countries"
                  :key="country.id"
                  class="flex h-12 cursor-pointer items-center"
                  @click="select(country)"
                >
                  <div class="flex-none pr-4">
                    <img
                      :src="country.img"
                      width="24"
                      height="24"
                      :alt="country.name"
                    />
                  </div>
                  <div class="grow text-sm text-neutral-black-1">
                    {{ country.name }}
                  </div>
                  <div
                    v-if="country.id == countrySelected"
                    class="flex-none pr-4"
                  >
                    <img
                      src="@/assets/svg/icons/icons-check.svg"
                      width="24"
                      height="24"
                    />
                  </div>
                </div>
              </div>
              <div v-else>
                <button
                  @click="expand"
                  class="h-11 w-full rounded border border-neutral-light-grey-1 text-sm text-neutral-black-2"
                >
                  <div class="flex items-center px-3.5">
                    <img
                      :src="storeConfig.country.img"
                      width="24"
                      height="24"
                    />
                    <div class="ml-2.5 grow text-left">
                      {{ storeConfig.country.name }}
                    </div>
                    <img
                      src="@/assets/svg/icons/icons-arrow-down.svg"
                      width="24"
                      height="24"
                      alt="close"
                    />
                  </div>
                </button>
              </div>
            </div>
            <div class="py-3.5">
              <button
                @click="confirm"
                class="h-11 w-full rounded bg-nutripink font-bodoni text-base text-white"
              >
                {{ t('GO_SHOP') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script setup>
const { t } = useI18n({
  useScope: 'local',
})

const { locale } = useI18n({ useScope: 'global' })
const { storeConfig, setCountry, getCountry } = useStoreConfig()
const { challenge, hide: hideCountrySelector } = useCountrySelector()

const expanded = ref(false)
const visibleSelector = ref(false)

const country = getCountry()
const countrySelected = ref(country.id)

const { data: countries } = await useFetch('/api/public/config/countries')

const expand = () => {
  expanded.value = true
}

const close = () => {
  hideCountrySelector()
}

const select = (country) => {
  countrySelected.value = country.id
}

const confirm = () => {
  const country = countries.value.find(function (element) {
    return element.id === countrySelected.value
  })
  locale.value = country.locale
  setCountry(country)
  close()
}

onMounted(() => {
  if (!document.body.classList.contains('modalon')) {
    document.body.classList.add('modalon')
  }
})

onUnmounted(() => {
  document.body.classList.remove('modalon')
})
</script>
